<template>
  <div class="target-images">
    <div
      class="target-image"
      :class="[targetImages.before === targetImage ? 'selected' : '']"
    >
      <v-btn
        v-if="targetImages.before"
        class="image-btn"
        @click="selectImage(targetImages.before)"
      >
        <NuxtImg
          :src="targetImages.before"
          alt="partial-render-preview-before"
          class="image"
          fit="contain"
        />
      </v-btn>
      <p class="image-label">
        Before
      </p>
    </div>

    <div
      class="target-image"
      :class="[targetImages.after === targetImage ? 'selected' : '']"
    >
      <v-btn
        v-if="targetImages.after"
        class="image-btn"
        @click="selectImage(targetImages.after)"
      >
        <NuxtImg
          :src="targetImages.after"
          alt="partial-render-preview-after"
          class="image"
        />
      </v-btn>
      <p class="image-label">
        After
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
const canvasStore = useCanvasStore()
const { targetImage, targetImages } = storeToRefs(canvasStore)

const selectImage = (image: string) => {
  canvasStore.setTargetImage(image)
}
</script>

<style lang="scss" scoped>
.target-images {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  max-height: 120px;

  .target-image {
    width: 100px;
    height: 96px;
    opacity: 0.5;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    &.selected {
      opacity: 1;

      .image-btn {
        border: 2px solid #fff;
      }
    }

    .image-btn {
      width: fit-content;
      max-width: 100%;
      height: 100%;
      max-height: 80px;
      background-color: transparent;
      padding: 0;
      border-radius: 4px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      &:deep(.v-btn__content) {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .image {
        width: 100%;
        height: 100%;
      }
    }

    .image-label {
      font-size: 10px;
      color: #fff;
      text-align: center;
    }
  }
}
</style>
